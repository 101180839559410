import CodeFlask from 'codeflask';

Prism.languages.myn = {
	'keyword': {
		pattern: /\b(def|with|as|pub|use)\b/,
	},
	'string': {
		pattern: /(^|[^\\])"(?:\\.|[^\\"\r\n])*"(?!\s*:)/,
		lookbehind: true,
		greedy: true
	},
	'comment': {
		pattern: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
		greedy: true
	},
	'number': /-?\b\d+(?:\.\d+)?(?:e[+-]?\d+)?\b/i,
	'punctuation': /[{}[\],]/,
	'operator': /:/,
	'boolean': /\b(?:false|true)\b/,
	'null': {
		pattern: /\bnull\b/,
		alias: 'keyword'
	}
};


const samples = {

fib:
`use "std/std.myn"

def fib 0 {
    as fib x {
        0
    }
}

def fib 1 {
    as fib x {
        1
    }
}

def fib #Num# {
    as fib x {
        (fib (x - 1)) + (fib (x - 2))
    }
}

def main { fib(10) }`,
	
hello:
`def main { Hello, World! }`,

list:
`use "std/prelude.myn"
use "std/opt.myn"
use "std/num.myn"

def SList<T> {
    aka T { _ }
    
    def #&#.val { with { #T# } }
    def #&#.nxt { with { #Opt<SList<T>># } }
    
    def #&#[0] {
        with { #T# }
        as l[0] {
            l.val
        }
    }

    def #&#[#Num#] {
        with { #T# }
        as l[n] {
            l.nxt[n - 1] 
        }
    }
    
    // TODO:
    // def #&#.prepend(#T#) {
    //     with { #&# }
    //     as l.append(e) {
    //         def l.nxt {
    //             with &.
    //         }
    //     }
    // }
}

def MyList {
    with { #SList<Num># }
    def &.val { 66 }
    def &.nxt {
        with { #SList<Num># } // TODO: remove
        def &.val { 72 }
        def &.nxt {
            with { #SList<Num># } // TODO: remove
            def &.val { 242 }
            def &.nxt {
                with { #SList<Num># } // TODO: remove
                def &.val { 25 }
                def &.nxt {
                    None
                }
            }
        }
    }
}

def main {
    MyList[3]
}`	
}

window.addEventListener('load', () => {
	const flask = new CodeFlask('#code', {
		language: 'myn',
		lineNumbers: true,
		tabSize: 4,
	});
	flask.addLanguage('myn', Prism.languages.myn);

	const $run = document.querySelector('#run');
	const $output = document.querySelector('#output > div');
	const $select = document.querySelector('select');

	$run.addEventListener('click', async () => {
		$output.innerText = 'please wait...';

		const res = await fetch('http://pi.thissma.fr:16700', {
			method: 'POST',
			body: flask.getCode()
		});
		
		const text = await res.text();
		$output.innerText = text;
		console.log(text);
	});

	flask.updateCode(samples[$select.value] || "");
	
	$select.addEventListener('change', () => {
		flask.updateCode(samples[$select.value] || "");
	})

});
